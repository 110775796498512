// Feature list
export const ADVANCED_CHARTING_FEATURE = 'advancedChartingFunctions';
export const AGG_CALCS_FEATURE = 'aggCalcs';
export const AI_CHAT_FEATURE = 'aiChat';
export const AI_CHAT_FINANCIAL_NEWS_FEATURE = 'aiChatFinancialNews';
export const AI_CHAT_LPL_FEATURE = 'aiChatLPLResearch';
export const AI_CHAT_SUPPORT_FEATURE = 'aiChatSupport';
export const AI_CHAT_SEC_FILINGS_FEATURE = 'aiChatSECFilings';
export const ALERTS_FEATURE = 'alerts';
export const ALL_FUND_HOLDINGS_FEATURE = 'allFundHoldings';
export const ANALYST_METRICS_FEATURE = 'analystMetric';
export const API_FEATURE = 'api';
export const AUTO_REFRESH_IMPORTED_PORTFOLIOS_FEATURE = 'autoRefreshImportedPortfolios';
export const AUTO_REFRESH_IMPORTED_CUSTOM_SECURITIES_FEATURE = 'autoRefreshImportedCustomSecurities';
export const CEFS_PAGE_FEATURE = 'cefsPage';
export const CHART_IMAGE_FEATURE = 'chartImage';
export const CHARTS_FEATURE = 'charts';
export const COMPARABLES_FEATURE = 'comparables';
export const CONSTITUENTS_FEATURE = 'indexConstituents';
export const CUSTOM_EMAIL_REPORTS_FEATURE = 'customEmailReports';
export const GENERATE_PDF_REPORT_FEATURE = 'pdfReport';
export const CUSTOM_SECURITIES_FEATURE = 'customSecurities';
export const CUSTOMIZE_PAGES_FEATURE = 'customizePages';
export const DASHBOARD_FEATURE = 'dashboard';
export const DATA_EXPORT_FEATURE = 'dataExport';
export const DATA_TABLES_FEATURE = 'dataTables';
export const DRAFT_PORTFOLIOS_FEATURE = 'draftModelPortfolios';
export const DYNAMIC_MODEL_PORTFOLIOS_FEATURE = 'dynamicModelPortfolios';
export const ECONOMIC_CALENDAR_PAGE_FEATURE = 'economicCalendarPage';
export const ECONOMIC_INDICATORS_PAGE_FEATURE = 'economicIndicatorsPage';
export const EDIT_CLIENT_PORTFOLIO_TAX_RATE_FEATURE = 'editClientPortfolioTaxRate';
export const EDIT_TALKING_POINTS_FEATURE = 'editTalkingPoints';
export const EDIT_CHARTS_FEATURE = 'editCharts';
export const EDIT_CLIENT_GROUP_FEATURE = 'editClientGroup';
export const EDIT_CUSTOM_EMAIL_REPORTS_FEATURE = 'editCustomEmailReports';
export const EDIT_CUSTOM_SECURITIES_FEATURE = 'editCustomSecurities';
export const EDIT_DASHBOARD_FEATURE = 'editDashboard';
export const EDIT_DYNAMIC_MODEL_PORTFOLIOS_FEATURE = 'editDynamicModelPortfolios';
export const EDIT_MODEL_PORTFOLIOS_FEATURE = 'editModelPortfolios';
export const EDIT_PDF_REPORT_BUILDER_FEATURE = 'editReportBuilder';
export const EDIT_RISK_PROFILES_FEATURE = 'editRiskProfiles';
export const EDIT_SCATTER_PLOT_FEATURE = 'editScatterPlot';
export const EDIT_SCENARIOS_FEATURE = 'editScenarios';
export const EDIT_WATCHLIST_FEATURE = 'editWatchlists';
export const ETFS_PAGE_FEATURE = 'etfsPage';
export const EVENTS_CALENDAR_PAGE_FEATURE = 'eventsCalendarPage';
export const EXCEL_FEATURE = 'excelAddin';
export const GUIDED_WORKFLOWS_FEATURE = 'guidedWorkflows';
export const HISTORICAL_DATA_FEATURE = 'histData';
export const HOUSEHOLD_PORTFOLIOS_FEATURE = 'householdPortfolios';
export const INDICES_PAGE_FEATURES = 'indicesPage';
export const INTEGRATIONS_FEATURE = 'integrations';
export const INTERACTIVE_ANALYSIS_FEATURE = 'interactiveAnalysis';
export const LEGACY_QUOTE_PAGE_FEATURE = 'legacyQuotePage';
export const LEGACY_FUND_PAGE_FEATURE = 'legacyFundPage';
// TODO Deprecate and remove after full roll out of LEGACY_QUOTE_PAGE_FEATURE
export const LEGACY_QUOTE_PAGE_FEATURE_DEV = 'legacyQuotePageDEV';
export const LEGACY_DATA_FEATURE = 'legacyData';
export const METRICS_FEATURE = 'metric';
export const MODEL_PORTFOLIOS_FEATURE = 'modelPortfolios';
export const MUTUAL_FUND_DATA_CAN_FEATURE = 'mutualFundDataCAN';
export const MUTUAL_FUND_DATA_USA_FEATURE = 'mutualFundDataUSA';
export const NEWS_FEATURE = 'news';
export const NAMING_CONVENTION_FEATURE = 'namingConvention';
export const NOTES_FEATURE = 'notes';
export const PASSKEYS_FEATURE = 'passkeys';
export const PDF_REPORT_BUILDER_FEATURE = 'reportBuilder';
export const PORTFOLIO_OPTIMIZER_FEATURE = 'portfolioOptimizer';
export const PORTFOLIO_STATEMENT_EXTRACTION_FEATURE = 'portfolioStatementExtraction';
export const PROPOSAL_PDF_MODULE_FEATURE = 'proposalPDFModule';
export const PUBLIC_SHARING_FEATURE = 'publicSharing';
export const REPORT_BRANDING_FEATURE = 'reportBranding';
export const RISK_PROFILES_FEATURE = 'riskProfiles';
export const SCATTER_PLOT_FEATURE = 'scatterPlot';
export const SCENARIOS_FEATURE = 'scenarios';
export const SCHWAB_SSO_FEATURE = 'schwab_sso';
export const SCREENER_FEATURE = 'screener';
export const STAFF_ONLY_FEATURE = 'staffOnly';
export const STOCKS_PAGE_FEATURE = 'stocksPage';
export const STRATEGY_FEATURE = 'strategy';
export const TALKING_POINTS_FEATURE = 'talkingPoints';
export const WATCHLIST_FEATURE = 'watchlists';
export const WATCHLIST_EXTRACTION_FEATURE = 'watchlistExtraction';
export const TRANSITION_ANALYSIS_FEATURE = 'transitionAnalysisPage';
export const RAYMOND_JAMES_INTEGRATION_FEATURE = 'raymondJamesIntegration';
export const ORION_INTEGRATION_FEATURE = 'orionIntegration';
export const VRGL_INTEGRATION_FEATURE = 'vrglIntegration';
export const REDTAIL_INTEGRATION_FEATURE = 'redtailIntegration';
export const BLACK_DIAMOND_INTEGRATION_FEATURE = 'blackDiamondIntegration';
export const BROADRIDGE_INTEGRATION_FEATURE = 'broadridgeIntegration';
export const SCHWAB_INTEGRATION_FEATURE = 'schwabIntegration';
export const ADDEPAR_INTEGRATION_FEATURE = 'addeparIntegration';
export const PERSHING_INTEGRATION_FEATURE = 'pershingIntegration';
export const NORTHWESTERN_MUTUAL_INTEGRATION_FEATURE = 'northwesternMutualIntegration';
export const AVISO_INTEGRATION_FEATURE = 'avisoIntegration';
